import Link from "next/link";

import { usePathname } from "next/navigation";

import {
  PriceCard,
  PriceCardContent,
  PriceCardValue,
  PriceCardBadge,
} from "@/components/price-card";

import { useStationsAveragePrice } from "@/api";

import { cn } from "@/lib/utils";
import { formatPrice } from "@/lib/formatters";

import LogoImage from "@/components/logo-image";

function Header() {
  const { data: averagePrice } = useStationsAveragePrice();
  const currentPath = usePathname();

  return (
    <header className="flex min-h-14 w-full items-center border-b border-border px-4 md:pl-6">
      <Link href="/" className="flex h-6 items-center gap-2 text-primary">
        <LogoImage className="size-5 fill-primary md:size-full" />
        <span className="-mt-1 inline-flex text-lg font-bold leading-none md:text-xl">
          pompwijzer
        </span>
      </Link>
      <div className="ml-auto flex items-center gap-4">
        <nav className="flex items-center border-r border-border pr-4 text-sm text-muted-foreground hover:text-foreground md:ml-6">
          <Link
            href="/stats"
            className={cn(currentPath === "/stats" && "text-foreground")}
          >
            Stats
          </Link>
        </nav>
        <div className="flex items-center gap-4">
          <span className="hidden text-sm text-muted-foreground md:block">
            Landelijke gemiddelde
          </span>
          <PriceCard className="border-none">
            <PriceCardContent className="gap-2">
              <PriceCardValue className="text-lg md:text-xl">
                {formatPrice(averagePrice?.euro95 ?? 0)}
              </PriceCardValue>
              <PriceCardBadge className="rounded-md bg-white px-2 py-1 text-xs font-semibold text-emerald-700">
                Euro95
              </PriceCardBadge>
            </PriceCardContent>
          </PriceCard>
        </div>
      </div>
    </header>
  );
}

export default Header;
